@import url(https://fonts.googleapis.com/css2?family=https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
/* .border-primary {
  border-color: #007bff !important;
} */
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
ul {
  padding-left: 0;
  margin-bottom: 0;
}
body {
  font-family: Lato, sans-serif !important;
}
.top-header {
  background-color: #f8f8f8;
}
.top-header .header-contact {
  padding: 9px 0;
}
.top-header .header-dropdown {
  color: #000;
  font-size: 14px;
  cursor: pointer;
  padding: 15px 25px;
}
.Mandarian-Orange {
  color: #8c1c40 !important;
}
.bg-primary-1 {
  background-color: #f85007;
}
.home {
  background-image: url(/static/media/banner3.7651bc32.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 84vh;
}
.home .slider-contain {
  justify-content: center;
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: center;
}
.slider-contain .btn-solid {
  border-color: #000 !important;
  background-image: linear-gradient(30deg, #000 50%, transparent 50%);
}
.slider-contain .btn-solid:hover {
  color: #000000 !important;
  background-color: #ffffff;
  background-image: linear-gradient(30deg, #000 30%, transparent 30%);
}

.home .slider-contain h1 {
  margin-top: 10px;
  color: #222222;
}
.text-center {
  text-align: center;
}
.text-align-initial {
  text-align: left;
  text-align: initial;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
  text-align: initial !important;
}
.text-transform-initial {
  text-transform: none;
  text-transform: initial;
}
.fa-small {
  font-size: small;
}
.text-secondary-1 {
  color: #a51c30 !important;
}
.fa-2x {
  font-size: 2em;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-solid {
  border-color: #ffc107 !important;
  background-image: linear-gradient(30deg, #ffc107 50%, transparent 50%);
  padding: 13px 29px;
  color: #ffffff !important;
  letter-spacing: 0.05em;
  border: 2px solid var(--theme-deafult);
  background-size: 540px;
  background-repeat: no-repeat;
  background-position: 0;
  transition: background 300ms ease-in-out;
}
.btn {
  line-height: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0;
  display: inline-block;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  vertical-align: middle;
}
.btn-solid:hover {
  background-position: 100%;
  color: #000000 !important;
  background-color: #ffffff;
  background-image: linear-gradient(30deg, #ffc107 30%, transparent 30%);
}

.btn:hover {
  transition: 0.3s ease-in-out;
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.classic-effect {
  position: relative;
  overflow: hidden;
}
.classic-effect:before,
.classic-effect:after,
.classic-effect span:before,
.classic-effect span:after {
  background: #f36221;
}
.classic-effect:before {
  left: 0;
  transition-delay: 0s;
}
.classic-effect:before,
.classic-effect:after {
  background: var(--theme-deafult);
  width: 25%;
  position: absolute;
  content: "";
  opacity: 0;
  transition: all 0.3s steps(4);
  z-index: 1;
  bottom: 100%;
  top: 0;
}
.classic-effect:after {
  left: 25%;
  transition-delay: 0.025s;
}
.classic-effect span:before {
  left: 50%;
  transition-delay: 0.05s;
}

.classic-effect span:before,
.classic-effect span:after {
  background: var(--theme-deafult);
  width: 25%;
  position: absolute;
  content: "";
  opacity: 0;
  transition: all 0.3s steps(4);
  z-index: 1;
  bottom: 100%;
  top: 0;
}
.classic-effect span:after {
  left: 75%;
  transition-delay: 0.15s;
}
.classic-effect:hover:before,
.classic-effect:hover:after {
  bottom: 0;
  opacity: 0.5;
}
.classic-effect:hover span:before,
.classic-effect:hover span:after {
  bottom: 0;
  opacity: 0.5;
}
a {
  transition: 0.5s ease;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.justify-content-center {
  justify-content: center !important;
}
.sticky.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: all 300ms ease;
  background: #fff;
  box-shadow: 0px 0px 8px #ddd;
  -webkit-animation: smoothScroll 1s forwards;
  animation: smoothScroll 1s forwards;
}
.slick-track {
  display: flex;
}
.advantage-section .advantage-slide-item {
  margin: 0 8px;
  position: relative;
  border: 1px solid #dee2e6 !important;
}
.advantage-section .advantage-slide-item p {
  position: absolute;
  bottom: 0;
  padding: 10px;
  margin-bottom: 0;
  font-size: smaller;
}
.advantage-section .advantage-slide-item h6 {
  text-align: center;
  color: #fff;
  padding: 5px;
  font-size: small;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1250px !important;
  }
}

.text-transform-initial {
  text-transform: none;
  text-transform: initial;
}
.fa-lg {
  font-size: 1.3333333333em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-lg {
  line-height: 1.1em;
}
.line-height-1 {
  line-height: 1;
}
p {
  color: #555;
  font-size: 16px;
  line-height: 1.2em !important;
}
.text-black {
  color: #000 !important;
}
.Pumpkin {
  color: #f36221 !important;
}

.bg-white-smoke {
  background-color: #f4f4f4 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-salomie {
  background-color: #fee080 !important;
}

.courses-section ul li {
  display: block;
  margin: 0.5rem 0;
  font-size: 15px;
  cursor: pointer;
}
.courses-section ul li:hover {
  color: #f36221;
}
.Camelot {
  background-color: #9e1b32;
}
.Gainsboro {
  background-color: #d8d8d8 !important;
}
.bg-black {
  background-color: #000;
}
.Regal-Blue {
  color: #1d3960;
}
.mobile-fix-option {
  display: none;
}
.classic-effect:before,
.classic-effect:after,
.classic-effect span:before,
.classic-effect span:after {
  background: #f36221;
}
.btn-solid {
  border-color: #ffc107 !important;
  background-image: linear-gradient(30deg, #ffc107 70%, transparent 30%);
}
.life-at-hkbk .btn-solid {
  color: #000 !important;
  background-image: unset !important;
}
.life-at-hkbk .btn-solid:hover {
  color: #000 !important;
  background-image: linear-gradient(
    30deg,
    #f85007 30%,
    transparent 30%
  ) !important;
}
font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.typing-animation {
  padding: 2px;
  max-width: -webkit-max-content;
  max-width: max-content;
}

.typing-animation h3 {
  color: #fff !important;
}
.typing-animation h3:last-of-type {
  width: 11em;
}
@media (max-width: 1199px) {
  .home {
    height: 85vh;
  }
}
@media (max-width: 768px) {
  .home {
    height: 85vh;
  }
}
@media (max-width: 576px) {
  .home .slider-contain {
    justify-content: flex-start;
    height: 43vh;
  }
  .slider-contain h1.fa-3x, .slider-contain h1.fa-2x {
    font-size: 1.6em;
  }
  .header-dropdown {
    display: none;
  }
  #sticky {
    margin-top: 20rem;
  }
  #sticky-section .sticky-col {
    border-bottom: 1px solid #666;
  }
  #sticky-section .sticky-col:nth-of-type(3) {
    border-bottom: unset;
  }
  form {
    border: 1px solid #dee2e6 !important;
  }
  .help-desk-info {
    font-size: 0.8em;
  }
  .why-hkbk .classic-effect {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .why-hkbk .classic-effect:nth-of-type(6) {
    border-bottom: unset;
  }
  /* .typing-animation {
    background-color: #000;
    min-width: 11em;
    padding: 2px;
    min-height: 1.6em;
  }

  .typing-animation h3 {
    font-size: 1rem;
    color: #fff !important;
  }

  .typing-animation h3:last-of-type {
    font-size: 1rem;
    color: #fff !important;
  } */
  .typing-animation {
    padding: 2px;
    max-width: -webkit-max-content;
    max-width: max-content;
    min-height: 1.6em !important;
  }

  .typing-animation h3 {
    font-size: 1rem;
    color: #fff !important;
    width: 8.5em;
  }
  .typing-animation h3:last-of-type {
    width: 11em;
  }
}
@media (max-width: 424px) {
  .slider-contain h1.fa-3x, .slider-contain h1.fa-2x {
    font-size: 1.6em;
  }
}
@media (max-width: 375px) {
  .slider-contain h1.fa-3x, .slider-contain h1.fa-2x {
    font-size: 1.2em;
  }
}

